<template>
  <div class="class">
    <banner :wrapperText="wrapperText" />
    <TopNav :list="nav" />
    <apply-process id="process" @updateStatus="getStatus"></apply-process>
    <OpenObject id="openObject"></OpenObject>
    <Apply id="apply"></Apply>
    <ClassType id="classType"></ClassType>
    <ClassManage id="classManage"></ClassManage>
    <Result id="result"></Result>
    <Example id="example"></Example>
    <Question id="question"></Question>
    <Search v-if="showSearchModal" @close="showSearchModal = false"  />
      <img class="search-portal" :src="searchIcon" alt=""  @click="showSearch"/>
    <!-- <apply-form v-if="isApply" @updateStatus='getStatus'></apply-form> -->
    <Footer />
  </div>
</template>
<script>
import Banner from './components/banner'
import TopNav from '@/components/TopNav'
import OpenObject from './components/object'
import Apply from './components/apply'
import ApplyProcess from './components/process'
import ClassType from './components/type'
import ClassManage from './components/manage'
import Result from './components/result'
import Example from './components/example'
import Question from './components/question'
import Search from './components/search'
// import ApplyForm from './components/applyForm'
import Footer from './components/footer'

export default {
  name: 'Classes',
  components: {
    Banner,
    TopNav,
    OpenObject,
    Apply,
    ApplyProcess,
    ClassManage,
    Result,
    Example,
    Question,
    ClassType,
    // ApplyForm,
    Footer,
    Search,
  },
  data () {
    return {
      wrapperText: {
        title: '关于我们',
        desc: '浙江西图盟数字科技有限公司为浙江省数字化设计与制造创新中心实体运营单位，基于核心股东单位和众多联盟机构在工程制造领域中累积的有效经验，以汽车行业为重点，以离散制造业为主要服务对象，为离散制造业的数字化改造提供定制化解决方案。',
        imgSrc: require('@/assets/class/class-bg.png'),
      },
      nav: [
        {
          name: '申请流程',
          hash: 'process',
        },
        {
          name: '开放对象',
          hash: 'openObject',
        },
        {
          name: '申请形式',
          hash: 'apply',
        },

        {
          name: '课题管理',
          hash: 'classManage',
        },
        {
          name: '课题揭榜',
          hash: 'result',
        },
        {
          name: '常见问题',
          hash: 'question',
        },
      ],
      isApply: false,
      searchIcon: require('@/assets/class-search-icon.svg'),
      showSearchModal: false,
    }
  },
  methods: {
    getStatus (status) {
      this.isApply = status
    },
    showSearch () {
      this.showSearchModal = true
    },
  },
}
</script>
<style scoped>
.class {
  font-family: PingFang SC;
}
.search-portal {
  position: fixed;
  right: -10px;
  top: 50%;
  cursor: pointer;
}
</style>
